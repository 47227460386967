import React from "react"
import Footer from "../components/footer"
import "../styles/customer-story.less"
// const loadText = require("src/utils").loadText
// const data = loadText("customer-story-talkcloud")
import { Top } from "../pages/customer-stories"
import { ConsultationPageForm } from "../components/form-biz"

const Case = ({ name, description, bgPic, logo }) => (
  <div
    className="story-case clearfix"
    style={{ backgroundImage: `url("${bgPic}")` }}
  >
    {/* <div className="page-middle"> */}
    <div className="story-case-content">
      <div className="font-28 semi-bold t-color clearfix name">
        {name}
        <img src={logo} alt={name} style={{ width: "70px" }} />
      </div>
      <div
        className="font-20 mt-32"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
    {/* </div> */}
  </div>
)
const Stats = ({ title, subtitle, stats }) => (
  <div className="story-stats">
    <div
      className="font-36 semi-bold t-color block-middle  mobile-padding"
      style={{ maxWidth: "1000px" }}
    >
      {title}
    </div>
    <div className="mt-64 font-28 semi-bold t-color">{subtitle}</div>
    <div className="mt-32 block-middle" style={{ maxWidth: "1065px" }}>
      {stats.map(({ name, stat }) => (
        <div className="story-stats-item">
          <div className="stats mt-32">{stat}</div>
          <div className="font-20 semi-bold t-color mt-8">{name}</div>
        </div>
      ))}
    </div>
  </div>
)
const Problem = ({ title, content, bg }) => (
  <div className="story-problem">
    <div
      className="full-bg"
      style={{ backgroundImage: `url("${bg}")`, backgroundPosition: "top" }}
    >
      <div className="font-28 semi-bold t-color mobile-padding">{title}</div>
      <div className="mt-8">
        {content.map(({ title, description }) => (
          <div className="mt-56">
            <div
              className="font-28 semi-bold t-color block-middle  mobile-padding"
              style={{ maxWidth: "739px" }}
            >
              {title}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className="story-problem-content block-middle font-20 mt-24"
            />
          </div>
        ))}
      </div>
    </div>
  </div>
)
const Solution = ({ title, subtitle, content, bg, pic, list }) => (
  <div
    style={{
      background:
        "linear-gradient(270deg,rgba(12,168,253,1) 0%,rgba(78,195,254,1) 100%)",
    }}
  >
    <div
      className="story-solution full-bg"
      style={{ backgroundImage: `url("${bg}")`, backgroundPosition: "top" }}
    >
      <div className="page-middle" style={{ position: "relative" }}>
        <div className="block-middle story-solution-content">
          <div className="font-28 semi-bold">{title}</div>
          <div className="font-28 semi-bold mt-64">{subtitle}</div>
          <div
            className="font-20 mt-24"
            style={{ maxWidth: "650px" }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {list
            ? list.map(({ title, description, subtitle }) => (
                <div>
                  <div className="font-20 semi-bold mt-32">{title}</div>
                  {subtitle && <div className="font-20 mt-24">{subtitle}</div>}
                  <ul className="mt-24 font-20">
                    {description.map(t => (
                      <li>{t}</li>
                    ))}
                  </ul>
                </div>
              ))
            : null}
          <img src={pic} alt={subtitle} style={{ width: "529px" }} />
        </div>
      </div>
    </div>
  </div>
)
const Result = ({ title, content }) => (
  <div className="story-result">
    <div className="story-result-title font-28 semi-bold">{title}</div>
    <div style={{ background: "#E1F3FE" }}>
      <div className="story-result-content-container block-middle">
        {content.map(({ title, description }) => (
          <div className="story-result-content">
            <div
              className="font-28 semi-bold t-color block-middle text-center mt-56"
              style={{ maxWidth: "643px" }}
            >
              {title}
            </div>
            <div className="mt-24">
              {description.map(d => (
                <div
                  className="content font-20 mt-16"
                  dangerouslySetInnerHTML={{ __html: d }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)
const Map = ({ pic, alt }) => (
  <div className="story-map page-middle">
    <img src={pic} alt={alt} />
  </div>
)
const Block = ({ picLeft, pic, picM, content = [], title, description }) => {
  return (
    <div className="story-block">
      <div className="page-middle story-block-content clearfix">
        <div className="imgContainer">
          <img src={pic} alt={title} />
        </div>
        <div className="textContainer">
          <div>
            <div className="font-28 semi-bold t-color">{title}</div>
            <picture className="story-block-m">
              <source srcSet={`${picM}`} media="(max-width: 640px)" />
              <img src={picM} alt={"picM"} />
            </picture>
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              className="font-20 mt-24"
            />
            {content.length > 0 && (
              <ul className="font-20">
                {content.map(c => (
                  <li className="mt-16">{c}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default function Home({ pageContext }) {
  const { data } = pageContext

  return (
    <div className="story">
      <Top seo={data.seo} topBanner={data.topBanner} />
      <div
        style={{
          background:
            "linear-gradient(360deg,rgba(255,255,255,1) 0%,rgba(245,249,255,1) 100%)",
        }}
      >
        <Case {...data.case} />
        <Stats {...data.byTheNumber} />
      </div>
      <Problem {...data.problem} />
      <Solution {...data.solution} />
      {data.leftRightLayoutContent && (
        <div
          className="box-rows-div"
          style={{ position: "relative", overflow: "hidden" }}
        >
          <img
            src="/customer-story/proposal-bg.svg"
            alt="proposal-bg"
            className="story-proposal-bg"
          />
          {data.leftRightLayoutContent.map(d => (
            <Block {...d} />
          ))}
        </div>
      )}
      {data.map && <Map {...data.map} />}
      <Result {...data.results} />
      <ConsultationPageForm {...data.consultation} source={data._key} />
      <Footer />
    </div>
  )
}
